/* .ayn-footer{
    position: fixed;
    bottom: 0;
} */
.ayn-footer-col{
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
}

@media (max-width: 414px) {
    .ayn-footer-col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        width: 100%;
    }
    .ayn-footer-copyright-nav{
        margin-top: 16px;
        margin-bottom: 16px;
    }
}
.ayn-footer-copyright-nav{
    color: #fff;
    font-size: 12px;
    text-align: center;
}
ayn-footer-contact-nav{
    color: #fff;
}
.ayn-footer-contact-btn{
    font-weight: 500;
    line-height: 40px;
    padding: 0 8px;
    font-size: 10px;
}