.detail-row{
    margin-bottom: 16px;
}
.ayn-detail-text{
    font-size: 12px;
    font-weight: 300;
}
.ayn-detail-title{
    font-size: 12px;
    font-weight: 500;
}