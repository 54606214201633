.wrapper {
    display: block;
}

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 1001;
    background: #001e37;
    opacity: 1;
    color: #fff;
    transition: all 0.3s;
    overflow-y: scroll;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    /* full screen */
    width: 100vw;
    height: 100vh;
    /* transparent black */
    background: rgba(0, 0, 0, 0.2);
    /* middle layer, i.e. appears below the sidebar */
    z-index: 1000;
    opacity: 0;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
}
/* display .overlay when it has the .active class */
.overlay.active {
    display: block;
    opacity: 1;
}

.big-menu-active{
    color: #ff5851 !important
}

.big-menu-active::after{
    width: 24px !important;
    opacity: 1 !important;
    visibility: visible !important;
    bottom: 0 !important;
    left: 50% !important;
    height: 2px !important;
    content: '';
    position: absolute !important;
    margin: 0 0 0 -12px !important;
}
.ayn-search-group-item{
 text-transform: none;
 line-height: 36px;
}
.ayn-fieldset{
    display: flex;
    justify-content: center;
}
.ayn-fieldset .form-group{
    width: auto;
}
.ayn-fieldset .wt-formoptions{
    position: relative;
}
.ayn-fieldset .wt-radioholder{
    left: auto;
}

.ayn-fieldset .ayn-search-group-span{
    height: 50px;
    font-size: 12px;
    padding-right: 12px;
    padding-left: 12px;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.ayn-fieldset .selected-search-type{
    width: 50%;
}

@media (max-width:720px){
	.wt-headervtwo .wt-navigationarea .wt-logo{
		width: auto !important;
	}
}