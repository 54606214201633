.ayn-pack-head h2{
    font-size: 14px;
    margin: 0;
}
.ayn-pack-head{
    padding: 16px 8px;
    border-bottom: 1px solid #ddd;
}
.ayn-pack{
    min-width: 200px;
    max-width: 250px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 10%);
    border: 1px solid #ddd;
    /* border-bottom: none */
}
.ayn-pack-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.ayn-pack-section{
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}
.ayn-pack-section i{
    font-size: 20px;
    margin-right: 8px;
}
.ayn-pack-section span{
    font-size: 12px;
}
.ayn-pack-section .fa-check{
    color: #4caf50;
}
.ayn-pack-section .fa-times{
    color: red;
}
.ayn-pack-price-content, .ayn-pack-select{
    padding: 8px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ayn-pack-price, .ayn-pack-price-content h5{
    margin: 0px;
}

.ayn-pack-foot{
    background-color: #f7f7f7;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.marchand-admin-card-paragraphe{
    font-size: 12px;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;;
}
.level-two{
    font-size: 10px;
}