.ayn-cond-head{
   color: #001e37;
   text-transform: none;
   text-align: center
}
.ayn-cond-subhead{
    color: #001e37;
    text-transform: none;
 }
.ayn-cond-title{
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: rgba(26, 26, 26, 0.9);
    font-size: 13px;
    margin-bottom: .5rem;
    line-height: 1.1;
}
.ayn-cond-content{
    color: rgb(38, 35, 35);
    padding: 0px 30px 0px 0px; /*20px 30px 20px 0px;*/
    text-align: justify;
    font-size: 12px;
    margin-bottom: 4px;
    display: block;
}
.level-2{
    margin-left: 16px;
}