.wt-registerformmain{
    padding: 10px !important;
}
.wt-select{ text-transform: none;}
#form-inscription input, #form-inscription select, #form-inscription textarea{
    font-size: 12px;
}
#form-inscription .form-card-header{
    height: 56px;
    background-color: rgb(247, 247, 247);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 6px -4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#form-inscription .form-card{
    float: left;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    margin-bottom: 24px;
}
#form-inscription .form-card-body{
    padding: 16px;
    padding-bottom: 32px;
    float: left;
}
.error-span{
    font-size: 10px;
    color: red;
    display: block;
    line-height: 14px;
}
.form-card-header h4{
    margin: 0px;
}

.form-title-container{
 /* border-top: 32px solid #f7f7f7 !important; */
 margin-top: 32px !important
}
.form-title{
    margin: 0;
    font-weight: 500;
    text-transform: uppercase;
    /* text-align: center; */
}
label{
    font-size: 12px !important;
    margin-bottom: 6px !important;
}
.file-label{
    margin-bottom: 0px !important;
}
.file-label-span{
    font-size: 10px;
    margin-bottom: 6px !important;
    line-height: 14px;
}


.form-cat-ul{
    justify-content: start !important; 
    flex-wrap: wrap !important; 
    margin-top: 8px; 
    /* border: 1px solid #ddd !important;  */
    /* border-radius: 8px */
    /* padding: 16px;  */
}
.form-subcat-ul{
    justify-content: start !important; 
    flex-wrap: wrap !important; 
    border: 1px solid #ddd !important; 
    padding: 16px; 
    border-radius: 8px
}
.form-control-feedback{
    position: absolute;
    right: 32px;
    margin-top: 12px;
}
.form-control-feedback.myspin{
    -webkit-animation: spin .8s ease infinite;
  animation: spin .8s ease infinite;
  color: #001e37;
}

.form-control-feedback.error{ color: red; }
.form-control-feedback.success{ color: green; }

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
/* .form-subcat-ul li a:hover{
    color: #fff;
} */
.cat-closer{
    margin: 0px !important;
    margin-left: 8px !important;
    color: #fff !important
}
.form-radio-container{
    display: flex;
    align-items: center;
    margin-top: 16px !important;
}
.form-radio-container label{
    margin-bottom: -24px !important;
}
.form-radio-content{
    display: flex !important;
    /* align-items: center;
    justify-content: space-evenly; */
    width: 100%;
    /* margin-top: 26px; */
    /* margin-bottom: -24px */
}
.form-radio{
    display: flex !important;
    flex-direction: column !important;
    width: 90px;
}
.form-radio-label{
    font-size: 10px;
}

/* @media (max-width: 991px) {
    ul.ayn-nav-ul { display: none !important }
  } */
.form-file-selecter{
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-top: 12px !important;
}
.file-selecter-btn{
    background-color: rgb(138, 119, 125) !important;
    text-transform: none !important;
    font: 400 12px/40px 'Poppins', Arial, Helvetica, sans-serif !important;
    cursor: pointer;
}
.logo-selecter{
    margin-top: 56px !important;
}

/* .wt-registerformmain{
    padding: 0px  !important;
} */