.wt-filtertag li .cat-chip {
    display: block;
    color: #767676;
    background: #fff;
    font-size: 12px;
    padding: 0 13px;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #ddd;
    cursor: pointer;
}

.ayn-cat-li .cat-chip.active {
    background-color: #f9a2a2;
    color: #fff;
}

.wt-filtertag li .cat-chip  i {
    opacity: 1;
    float: none;
    color: #ff5851;
    text-shadow: none;
    margin: 0 15px 0 -25px;
    display: inline-block;
    vertical-align: baseline;
}
.filter-select::after{
    line-height: 40px !important;
}
.wt-widgettitle h2{
    text-transform: none !important;
}