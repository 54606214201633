.ayn-top-bar{
    height: 61px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 48px;
}
.ayn-dropbtn {
    /* background-color: #4CAF50;
    color: white; */
    /* padding: 16px;
    font-size: 16px;
    border: none; */
    cursor: pointer;
}

.ayn-dropdown {
    position: relative;
    /* display: inline-block; */
    display: flex;
    align-items: center;
}
.ayn-avatar {
    width: 32px;
    height: 32px;
    background-color: #001e37;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.ayn-dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 42px;
    right: -20px;
    border-radius: 4px;
}

.ayn-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.ayn-dropdown-content a:hover {
    background-color: #f1f1f1;
}

.ayn-dropdown:hover .ayn-dropdown-content {
    display: block;
}

.ayn-admin-btn-toggle-mobile{
    margin: 0;
    background-color: #fff;
    color: #001e37;
    display: none;
}

.admin-nav-container-mobile-display{
    display: block !important;
    margin-top: -48px;
    margin-left: 0px;
    z-index: 1;
    transition: all 0.3s;
}
.admin-nav-container-mobile-none{
    display: none !important;
}

@media (max-width:920px){
	.ayn-admin-btn-toggle-mobile{
        display: block;
    }
}
@media (min-width:921px){
    .admin-nav-container-mobile-display{
        display: none !important;
    }
}
