/* 0 a 576px --> Mobile */
/* 576 a 992px --> Tablette */
/* 992 a 3000px --> Desktop */
/* #wt-wrapper, .wt-contentwrapper{ height: 100%;} */
.app-header{
  background-color: #001e37 !important;
}
.ayn-btn-toggle{ 
  background-color: #001e37 !important;
  margin-left: 8px;
  color: #fff;
}
.ayn-btn-toggle-mobile{
  margin: 12px 0;
  float: left;
  color: #001e37;
  background-color: transparent;
}
.ayn-header{ 
  color: #001e37;
  font-weight: bold !important;
  text-overflow: ellipsis;
  text-transform: capitalize !important;
  white-space: nowrap;
  overflow: hidden;
}
.ayn-search-container{
  display: flex;
  width: calc(100% - 168px);
  justify-content: center
}

@media (max-width: 991px) {
  .ayn-search-container{
    width: 100%;
    margin-bottom: 16px;
  }
  .ayn-search-container form{
    width: 100%;
    margin: 0;
  }
  .wt-logo {
    float: left;
    margin: 22px auto;
    width: calc(100% - 58px);
    text-align: center;
}
}

/******** SIZE A LA QUELLE MENU BTN APPARAIT *********/
@media (max-width:720px){
	.ayn-btn-toggle-mobile{
    position: relative;
    margin-top: -9px;
    padding-left: 0px;
  }
  .ayn-tab-header button{ font-size: 12px; }
  .ayn-progress{ height: 8px !important;}
  /* .ayn-produit-phr-col{
    min-width: 40% !important;
    margin-right: 24px !important;
  } */
}


.ayn-produit-phr-row, .ayn-cat-ul{
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: space-between;
}


.ayn-produit-phr-col{
  max-width: 31%;
}

.ayn-cat-li{
  display: ruby;
}
.ayn-cat-li a.active{
  background-color: #f9a2a2;
  color: #fff;
}
.ayn-vpay-img-container{
  position: relative;
  width: 26px;
  height: 26px;
  margin-left: auto;
  margin-right: auto;
  top: 14px;
  z-index: 99;

}
.ayn-vpay-img{
  width: 25px;
  height: 25px;
  position: relative;
  border: 2px solid #fff;
  border-radius: 90px;
}
.ayn-certifie-img{
  width: 18px;
  height: 18px;
  position: relative;
  /* border: 2px solid #fff;
  border-radius: 90px; */
}
.ayn-btn-toggle-mobile i{
  font-size: 30px;
  font-weight: bold;
  margin-left: 0px;
  color: #001e37;
}
.ayn-mobile-card-content{
  display: flex;
  flex-direction: column;
  width: calc(70% - 108px);
  /* width: calc(100% - 108px); */
  padding: 8px;
  /* background: linear-gradient(90deg, rgba(28,26,26,1) 69%, rgba(28,26,26,0.5144258386948529) 83%, rgba(28,26,26,0.01302527847076329) 97%) */
  background: linear-gradient(90deg, #212529c9 33%, rgb(28 26 26 / 42%) 70%, rgba(28,26,26,0.01302527847076329) 97%);
}
.ayn-mobile-detail-card .ayn-mobile-card-content{
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: #fff !important;
}
.ayn-detail-card .ayn-mobile-card-content{ width: 100%; }
.ayn-mobile-card-title{
  display: flex;
  align-items: flex-start;
}
.ayn-title{
  color: #001e37;
}
.ayn-navigation{
  display: flex;
  justify-content: space-between;
}
ul.ayn-nav-ul{ display: flex !important; /*overflow-y: hidden;*/ width: calc(100% - 300px);}
@media (max-width: 991px) {
  ul.ayn-nav-ul { display: none !important }
}
/* @media (min-width:576px) {
  ul.ayn-nav-ul { display: none !important }
} */
.ayn-navbar{
  text-align: left !important;
}
/* .ayn-nav-item{
  margin: 0px 8px;
} */
.ayn-nav-item a{
  border-radius: 8px;
}
.ayn-card{
  background-color:  #fff;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%)
}
.ayn-detail-cover{ 
  width: 100%;
  margin: 0;
}
.ayn-detail-cover img{  width: 100%; }
.ayn-tab-header{
  display: flex;
  justify-content: space-evenly;
  padding: 0 8px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
}
.ayn-tab-header button{
  background-color:  transparent;
  padding-bottom: 8px;
  color: #001e37;
}
.ayn-tab-item.active{
  border-bottom: 3px solid red;
}
.ayn-tab-item:focus{
  outline: 0;
}
.ayn-progress{
  margin-bottom: 4px;
  height: 12px;
}
#form-inscription .ayn-btn-submit-login{
  padding: 0 18px;
}
#form-inscription .ayn-btn-submit-login, #form-filter .ayn-btn-submit-login{
  height: 40px;
  background: #001e37;
  text-transform: capitalize;
  font: 100 13px/40px 'Poppins', Arial, Helvetica, sans-serif;
}
#form-inscription input, #form-inscription select { height: 40px;}
#form-inscription .wt-select::after { top: -5px }
#form-inscription  .ayn-btn-next, #form-filter .ayn-btn-nex{
  height: 40px;
  background: #ea2165;
  text-transform: capitalize;
  font: 100 13px/30px 'Poppins', Arial, Helvetica, sans-serif;
}
#form-inscription .ayn-btn-submit, #form-filter .ayn-btn-submit{
  height: 40px;
  background: #ea2165;
  text-transform: capitalize;
  font: 100 13px/40px 'Poppins', Arial, Helvetica, sans-serif;
}
#form-filter input, #form-filter select { height: 30px; padding-top: 2px; padding-bottom: 2px;}
#form-filter .wt-select::after{ line-height: 30px; }
#form-filter label{ margin-bottom: 0px !important; }
#form-filter {
  width: 268px;
  padding: 16px;
}
#form-filter .form-group{
  margin-bottom: 8px;
}
#form-filter input, #form-filter select, #form-filter textarea{
  font-size: 12px;
}
/* #form-inscription .wt-radio input[type="radio"] + label::before{
  left: 45%;
}
#form-inscription .wt-radio input[type="radio"] + label::after {
  left: calc(45% + 5px);
} */
.ayn-store-card-li{ 
  line-height: 15px !important;
  font-size: 12px !important;
  margin-bottom: 6px;
  width: 100% !important;
}
.li-span{
  width: 100% !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ayn-categorie-title{ margin-top: 24px; margin-bottom: 0;}
.ayn-link{ color: #55acee;}
.store-carousel li{ padding: 0px 4px;}
.react-multiple-carousel__arrow--right{ right: 5% !important;}
.react-multiple-carousel__arrow--left{ left: 5% !important;}
.react-multi-carousel-track li, .react-multi-carousel-dot-list li{ list-style: none !important;}
li.react-multi-carousel-item{ padding-left: 0 !important; }
#pub-carousel .react-multi-carousel-track { max-height: 640px; }

.search-input{
  height: 32px !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  /* margin-bottom: 8px; */
  font-size: 11px !important;
}

.table-actions-container{
  display: flex;
  justify-content: space-between;
  margin: 0 40px 12px 40px
}
.table-actions-btn {
  font-size: 12px;
  text-transform: none;
  color: #fff;
}
.table-action-btn-filter{
  margin-left: 4px;
  margin-right: 4px;
  background-color: #000;
}
.table-action-btn-search{
  margin-left: 4px;
  background-color: #001e37;
}

@media (max-width: 420px) {
  .table-actions-container{ flex-direction: column;}
  .table-action-btn-filter, .table-action-btn-search { margin-top: 8px;}
}

/*=========================MODAL========================*/
.modal-action-btn, .filter-action-btn{
  font-size: 12px;
  text-transform: none;
  color: #fff;
  margin-left: 4px;
  margin-right: 4px;
}
.modal-actions-container{
  text-align: end;
  margin-top: 16px;
  bottom: 0px;
  position: fixed;
  width: calc(100% - 0px);
  background: #f7f7f7;
  padding: 16px 24px;
  margin-left: -25px;
}
.modal-body-content{
  margin-bottom: 100px;
}
.filter-actions-container{
  text-align: end;
  /* margin-top: 16px;
  bottom: 0px;
  position: fixed;
  width: calc(100% - 0px);
  background: #f7f7f7;
  padding: 16px 24px;
  margin-left: -25px; */
}
/* .ReactModal__Overlay--after-open.form{
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 2
} */
/* .ReactModal__Content .ReactModal__Content--after-open */
/* .ReactModal__Content, .ReactModal__Content--after-open{
  position: relative !important;
  inset: 0 0 0 68px !important; */
  /* margin-top: 36px; */
  /* width: 500px; */
  /* min-height: 164px; */
  /* max-height: 100%
} */

.chip-clear-all::after{
  width: 0;
  height: 0;
  top: -1px;
  content: '';
  right: -20px;
  position: absolute;
  border-top: 16px solid transparent;
  border-left: 20px solid #ff5851;
  border-left-color: rgb(255, 88, 81);
  border-bottom: 15px solid transparent;
}
.chip-clear-all{
  background: #ff5851;
  color: #fff;
  border-radius: 0;
  position: relative;
  overflow: visible;

  display: block;
  font-size: 12px;
  padding: 0 13px;
  border: 1px solid #ddd;
  cursor: pointer;
}
/*=====================MODAL ALERT============================*/
.ReactModal__Content.load-modal{
  position: absolute;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid #ddd;
  top: 50% !important;
  max-width: 400px;
}
.ReactModal__Content.alert-modal{
  position: absolute;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid #ddd;
  top: 48px !important;
  max-width: 400px;
}
.ReactModal__Content.alert-modal.commentaire-reply{
  width: 400px;
}
.modal-alert-actions-container{
  float: right;
}
/*=====================MODAL DETAIL============================*/
.ReactModal__Content.detail{
  position: relative !important;
  /* inset: 0 0 0 68px !important; */
  /* margin-top: 36px; */
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  max-width: calc(100% - 1px);
  max-height: calc(100% - 1px);
  background-color: #f7f7f7;
  overflow-y: auto;
  padding: 24px;
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ayn-btn-donner-avis{
  font-size: 12px;
  height: 32px;
  line-height: 30px;
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0,0,0,0.05);

  box-sizing: border-box;
  transition: all .2s;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.17);
  color: #001e37;
  cursor: pointer;
  /* display: inline-block; */
  font-family: "Roboto",sans-serif;
  font-style: normal;
  font-weight: 500;
  min-width: 40px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  /* vertical-align: middle; */
  background: none;
  margin: -48px 0 0 0;
  float: right;
}
.btn-primary, .btn-primary:hover, .btn-primary:active{
  background-color: #001e37;
  border-color: #001e37;
}
.btn:disabled, .btn-primary:disabled{
  opacity: 0.22;
  background-color: #001e37;
  border-color: #001e37;
}

.ayn-btn-marchand-demande{
  background: #fff;
  height: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  min-width: 194px;
  margin-bottom: 16px;
}

.ayn-btn-marchand-demande:hover{
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%)
}

.ayn-btn-marchand-demande i{
  font-size: 36px;
  color: #001e37;
}
.ayn-btn-marchand-demande h6{
  font-size: 12px;
  color: #001e37;
  text-transform: none;
  margin-top: 12px;
}

.ayn-skip-btn{
  height: 36px;
  font-size: 12px;
  font-weight: 400;
  line-height: 29px;
  padding: 0 16px;
  border-radius: 20px;
}
.span-a{
  color: #ff5851;
  cursor: pointer;
}

.span-info{ cursor: pointer; margin-left: 8px; }
.span-info i{
  font-size: 16px;
}


@keyframes slide {
  to {
    transform: translate(-100%);
  }
}

#marquee {
  /* color: #FE5501;
  background: #111; */
  /* width: 310px;
  height: 80px; */
  /* line-height: 80px; */
  position: relative;
  display: inline-block;
  /* top: 10px; */
  /* left: 100px; */
  overflow: hidden;
  text-overflow: hidden;
  white-space: nowrap;
}

#text {
  transform: translate(310px);
  /* font-family: "Consolas"; */
  display: inline-block;
  text-align: center;
  font-weight: bold;
  /* height: 80px;
  font-size: 40px; */
  animation-name: slide;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: slide;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  white-space: nowrap;
}

/*  POPOVER ARROW */
.ayn-cert-vpay-info-container{
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
  position: absolute;
  margin-top: 24px;
  margin-bottom: -23px;
  min-width: 270px;
  max-width: 400px;
  top: -124px;
  z-index: 9999;
}

.ayn-cert-vpay-info-containe::after{
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.ayn-cert-vpay-info-container::after {
  content: "";
  position: relative;
  /* top: 100%; */
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  top: 28px
}


/**===========================MAP=================================**/
.map-marker-icon{
  font-size: 10px !important;
  left: -42px !important;
  position: relative !important;
  /* color: #687787 !important; */
  /* -webkit-text-stroke: 1px black; */
   color: #546c78 !important;
   text-shadow:
       1px 1px 0 #fff,
     -1px -1px 0 #fff,  
      1px -1px 0 #fff,
      -1px 1px 0 #fff,
       1px 1px 0 #fff;
}

/***********************************************************************************
                                  scroll bare
************************************************************************************/
::-webkit-scrollbar { width: 6px; height: 6px; }
/* Track */
::-webkit-scrollbar-track { box-shadow: inset 0 0 5px transparent; border-radius: 10px; }
/* Handle */
::-webkit-scrollbar-thumb { background: #e91e63; border-radius: 10px; }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #e91e63; }