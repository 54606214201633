table > tbody > tr > td, table > thead > tr > th{
    border: none;
    line-height: 1.5;
    text-align: start;
    font-size: 12px;
    padding: 8px 8px 8px 16px !important;
    
}
table > tbody > tr > td{
    font-weight: 200;
}
table > thead > tr > th{
    font-weight: 600;
}

.page-item{ padding: 0px;}
tfoot > tr > td > nav > ul.pagination{ float: right;}
tfoot > tr > td > nav { display: inline;}
.pagination-select{
    height: 24px;
    padding: 2px;
    width: 56px;
    margin: 0px 8px;
}
.pagination-span{
    font-size: 12px;
    font-weight: 100;
    margin: 0px 4px;
}
.pagin-count{
    margin-right: 16px;
}
.page-link{
    z-index: 0 !important;
    color: #001e37;
}
.page-item.active .page-link{
    background-color: #001e37;
    border-color: #001e37

}