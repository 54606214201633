.pop-container{
    position: absolute;
    top: 100%;
    z-index: 1000;
    min-width: 6rem;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    background-color: #fff;
    /* background-clip: padding-box; */
    border: 1px solid #ddd;
    border-radius: .25rem;
    right: 0;
}
.pop-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-size: 12px;
    font-weight: 100;
    color: #000;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.pop-item:hover {
    background-color: #ddd;
    color: #000 !important;
}