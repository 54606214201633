.marchand-admin-card{
    float: left;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #ddd;
    width: 100%;
}
.marchand-admin-card-action-btn{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
.marchand-admin-card-section-body{
    padding-bottom: 16px;
    float: left;
    width: 100%;
}
.marchand-admin-card-section-header h4{
    margin: 0px;
}
.marchand-admin-card-section-body p, .marchand-admin-card-section-body h1{ margin: 0px }
.marchand-admin-card-btn{
    padding: 0 16px;
    position: relative;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    text-transform: none;
    font: 400 12px/36px 'Poppins', Arial, Helvetica, sans-serif;
}
.marchand-admin-card-btn-primary{
    background-color: #001e37;
    color: #fff;
    margin-left: 8px;
}
.marchand-admin-card-btn-primary:hover{
    background-color: #001e37;
}
.marchand-admin-card-btn-secondary{
    background-color: #707070;
    color: #fff;
}
.marchand-admin-card-btn-secondary:hover{
    background-color: #707070;
}
.marchand-admin-card-btn-danger{
    background-color: #ff5851;
    color: #fff;
    margin-left: 8px;
}
.marchand-admin-card-btn-danger:hover{
    background-color: #ff5851;
}
.form-group-certif{
    padding: 2px
}
.form-group-certif input{
    height: 40px;
    font-size: 12px;
}
.marchand-admin-card .form-group{
    margin: 0px;
}