li{ 
    list-style: none !important;
    padding-left: 1rem;
    color: #001e37;
    font-size: 14px;
}
li.active{
    background-color: #001e37;
}
.nav-head{ margin-top: 24px; }
.ayn-nav-link{
    display: block;
    padding: .5rem 1rem;
    color: #001e37;
}
.ayn-nav-icon{ margin-right: 16px; font-size: 18px;}
.active > .ayn-nav-link{
    color: #fff;
}
.left-side{
    width: 68px;
    height: 100%;
    background-color: #001e37;
    position: fixed;
}
.nav-container{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 68px;
    width: 280px;
    height: 100%;
    position: fixed;
    background-color: #efefef;
}

@media (max-width:920px){
	.nav-container, .left-side{
        display: none !important;
    }
    #wt-main{
        margin-left: 0px !important;
    }
}